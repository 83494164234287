import Vue from 'vue';

import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore'

const fapp = firebase.initializeApp({
  apiKey: `${process.env.VUE_APP_FIREBASE_API_KEY}`,
  projectId: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}`
}, 'qcpos')

const firestore1 = fapp.firestore();

firestore1.settings({
  cacheSizeBytes: 31457280 //WARNING: Default is 40 MB.
});

firestore1.enablePersistence()
.catch(err => {
  console.error('Firestore offline persistence failed', err.code);
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  }
  else if (err.code == 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

// AIR3
const firebaseConfig = {
  apiKey: `${process.env.VUE_APP_AIR3_FIREBASE_API_KEY}`,
  authDomain: `${process.env.VUE_APP_AIR3_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.VUE_APP_AIR3_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.VUE_APP_AIR3_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.VUE_APP_AIR3_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.VUE_APP_AIR3_FIREBASE_APP_ID}`
};

const air3Fapp = firebase.initializeApp( firebaseConfig, 'air3');
const firestore2 = air3Fapp.firestore();
firestore2.settings({
  cacheSizeBytes: 31457280 //WARNING: Default is 40 MB.
});

firestore2.enablePersistence()
.catch(err => {
  console.error('Firestore offline persistence failed', err.code);
  if (err.code == 'failed-precondition') {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  }
  else if (err.code == 'unimplemented') {
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

export const firestore = firestore1;
export const fauth = fapp.auth();
export const fauthPersistence = firebase.auth.Auth.Persistence;
export const FirestoreDataEvent = new Vue();

export const air3Firestore = firestore2;
export const air3Fauth = air3Fapp.auth();
export const air3FauthPersistence = firebase.auth.Auth.Persistence;
